/* Dropdown styles */
.wrapper{
  position: relative;
}
.select-btn { /* I removed li here*/
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select-btn{
  padding: 0.5rem 1rem;
  font-size: 22px;
  background: #fff;
  border-radius: 7px;
  justify-content: space-between;
  font-size: 16px;
}
.select-btn i{
  font-size: 16px;
  transition: transform 0.3s linear;
}
.wrapper.active .select-btn i{
  transform: rotate(-180deg);
}
.content-drop{
  display: none;
  padding: 12px 12px;
  margin-top: 15px;
  background: #fff;
  border-radius: 7px;
  position: absolute;
  top: 50px;
}
.wrapper.active .content-drop{
  display: block;
}
.content-drop .search{
  position: relative;
}
.search i{
  top: 50%;
  left: 15px;
  color: #999;
  font-size: 20px;
  pointer-events: none;
  transform: translateY(-50%);
  position: absolute;
}
.search input{
  height: 50px;
  width: 100%;
  outline: none;
  font-size: 17px;
  border-radius: 5px;
  padding: 0 20px 0 43px;
  border: 1px solid #B3B3B3;
}
.search input:focus{
  padding-left: 42px;
  border: 2px solid #4285f4;
}
.search input::placeholder{
  color: #bfbfbf;
}
.content-drop .options{
  margin-top: 10px;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 7px;
}
.options::-webkit-scrollbar{
  width: 7px;
}
.options::-webkit-scrollbar-track{
  background: #f1f1f1;
  border-radius: 25px;
}
.options::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 25px;
}
.options::-webkit-scrollbar-thumb:hover{
  background: #b3b3b3;
}
.options li{
  height: 50px;
  padding: 0 13px;
  font-size: 16px;
}
.options li:hover, li.selected{
  border-radius: 5px;
  background: #f2f2f2;
}
