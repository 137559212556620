
.resume_pic {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/* 
@media (max-width: 500px) {

  .resume_pic {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -60px;
  }
  .resume_body {
    width: 150px;
    height: 250px;
    padding: 50px;
  }
} */